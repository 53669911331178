import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="container mx-auto">
        <div className="flex flex-col justify-center">
        <h1 className="text-4xl font-bold text-center  inline-block my-8 mb-4 p-3">404</h1>
        <h2 className="text-2xl font-bold inline-block my-8 mt-4 p-3 text-center">
          Unfortunately this page cannot be found
        </h2>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
